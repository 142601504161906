<template>
<div>
<template v-if="this.$i18n.locale === 'en'">
  <CRow class="d-flex justify-content-center">
    <CCol md="11">
      <CCard class="shadow-sm">
        <CCardBody class="text-black m-5">
          <CRow>
            <router-link to="/manual" replace="" class="text-muted">
              <i class="fas fa-chevron-left fa-lg"></i>
            </router-link>
          </CRow>
          <h2 class="text-center">
            Automatic Notification Settings
          </h2>
          <p class="text-center text-description">
            Stores can configure automatic notifications via Line Notify API to stay informed about every transaction and point earning/redeeming activity.
          </p>
          <hr />
          <p>
            1. Go to the Settings menu, or
            <router-link to="/crm/settings#linenotify">
              <strong> click here </strong>
            </router-link>.
          </p>
          <p>
            2. Enter the Access Token obtained from Line Notify API and click Save.
          </p>
          <p class="text-center">
            <img
              src="/img/manual/en/notify.png"
              class="img-fluid"
              width="70%"
              alt="Line Notify"
            />
          </p>
          <strong>
            * You can find details on obtaining the Token from Line Notify API as follows:
          </strong>
          <line-notify-guide></line-notify-guide>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<template v-else>
  <CRow class="d-flex justify-content-center">
    <CCol md="11">
      <CCard class="shadow-sm">
        <CCardBody class="text-black m-5">
          <CRow>
            <router-link to="/manual" replace="" class="text-muted">
              <i class="fas fa-chevron-left fa-lg"></i>
            </router-link>
          </CRow>
          <h2 class="text-center">
            ตั้งค่าแจ้งเตือนอัตโนมัติ
          </h2>
          <p class="text-center text-description">
            ร้านค้าสามารถตั้งค่าการแจ้งเตือนอัตโนมัติผ่าน Line Notify API ได้
            <br />
            เพื่อทราบความเคลื่อนไหวของทุกการทำรายการ การเข้า/ออกของคะแนน
          </p>
          <hr />
          <p>
            1. ไปที่ เมนูตั้งค่า หรือ
            <router-link to="/crm/settings#linenotify">
              <strong> คลิกที่นี่ </strong>
            </router-link>
          </p>
          <p>
            2. กรอก Acces Token ที่ได้จาก Line Notify API แล้วกดบันทึก
          </p>
          <p class="text-center">
            <img
              src="/img/manual/notify.png"
              class="img-fluid"
              width="70%"
              alt="Line Notify"
            />
          </p>
          <strong>
            * สามารถดูรายละเอียดการออก Token จาก Line Notify API ได้ดังนี้
          </strong>
          <line-notify-guide></line-notify-guide>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
</div>
</template>

<script>
import LineNotifyGuide from '../reward/settings/LineNotifyGuide'
export default {
  components: {
    LineNotifyGuide,
  },
}
</script>
